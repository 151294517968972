export function getDateFromUnixTimestamp(
    unixTimestamp: number,
    separator: 'dot' | 'dash' = 'dot'
): string {
    const timestamp = unixTimestamp * 1000;
    const date = new Date(timestamp);
    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();

    if (separator === 'dash') {
        return `${day}-${month}-${year}`;
    }

    return `${day}.${month}.${year}`;
}
