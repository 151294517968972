import React from 'react';
import { Link } from 'gatsby';

import {
    container,
    listHeader,
    list,
    headerItem,
    listItem,
    badge,
    action,
    details,
    name,
    pagination,
    loading,
    initialLoading,
    loader,
    empty,
    statusNew,
    statusWaiting,
    statusApprovedWaiting,
    statusApprovalCopy,
    statusApproved,
    statusRequest,
    statusRejected,
} from './activations-list.module.scss';

import EditIcon from '../../assets/images/svg/edit.svg';
import DownloadIcon from '../../assets/images/svg/download.svg';

import { TLocale } from '../../locale';
import { IActivation, TActivationStatus } from '../../models/activation.model';
import { IMedia } from '@alterpage/gatsby-source-alterpress';
import { getDateFromUnixTimestamp } from '../../utils/get-date-from-unix-timestamp';
import { getFileBlob } from '../../api-clients/get-file-blob';
import { downloadBlob } from '../../utils/download-blob';
import { useList } from '../../hooks/use-list';
import { getUserTokenData } from '../../utils/get-user-token-data';
import { usePagePathname } from '../../hooks/use-page-pathname';
import useTranslations from '../../hooks/use-translations';

import { toast } from '../hoc/toast';
import PanelLayout from '../../layouts/panel-layout';
import Button from '../atoms/button';
import Pagination from '../molecules/pagination';
import Loader from '../atoms/loader';

const ActivationsList = () => {
    const t = useTranslations('ActivationsList');
    const token = getUserTokenData()?.token || '';
    const pathNames = usePagePathname();

    const {
        data,
        status: calendarStatus,
        handleChange,
    } = useList<IActivation>({
        endpoint: '/activations/calendar',
        token: token,
        initialParams: { year: getInitialParams().year, month: getInitialParams().month },
        paramsWhiteList: ['page'],
    });
    const activations = data ? Object.values(data).filter((item) => item) : [];

    const getActivations = async (month: number, year: number) => {
        const yearString = year.toString();
        const monthString = month < 10 ? `0${month}` : month.toString();
        handleChange({ year: yearString, month: monthString });
    };

    const {
        items,
        status: listStatus,
        paginationPaths,
        isInitialLoading,
    } = useList<IActivation>({
        endpoint: '/activations?expand=media',
        perPage: 12,
        token: token,
        ignoredParams: ['year', 'month'],
    });

    const downloadDocument = async (document: IMedia) => {
        try {
            const { data } = await getFileBlob(document.url);
            const fileBlob = new Blob([data], { type: `${document.type}` });
            downloadBlob(fileBlob, document.name);
        } catch {
            toast(t.downloadError, { type: 'error', autoClose: 3000 });
        }
    };

    return (
        <PanelLayout
            summary={'list'}
            showHeaderDate={true}
            showSummaryCalendar={true}
            showSummaryButton={true}
            title={t.title}
            innerTitle={t.innerTitle}
            activations={activations}
            onMonthChange={getActivations}
            contentClassName={`${container} ${
                listStatus === 'loading' && !isInitialLoading ? loading : ''
            } ${isInitialLoading ? initialLoading : ''}`}
            isLoading={calendarStatus === 'loading'}
        >
            {isInitialLoading && <Loader className={loader} />}
            {listStatus === 'success' && items && items.length === 0 && (
                <p className={empty}>{t.empty}</p>
            )}
            {items && items.length > 0 && (
                <>
                    <div className={listHeader}>
                        <p className={headerItem}>{t.date}</p>
                        <p className={headerItem}>{t.name}</p>
                        <p className={headerItem}>{t.step}</p>
                        <p className={headerItem}>{t.statusLabel}</p>
                    </div>
                    <div className={list}>
                        {items.map((item, i) => {
                            return (
                                <div className={listItem} key={`list-item-${i}`}>
                                    <p>{getDateFromUnixTimestamp(item.createdAt, 'dash')}</p>
                                    <p className={name}>{item.name}</p>
                                    <p>{item.step}/3</p>
                                    <p className={`${badge} ${getStatusClass(item.status)}`}>
                                        {getStatusName(item.status, t)}
                                    </p>
                                    <div>
                                        {item.availableActions.edit && (
                                            <Link
                                                className={action}
                                                to={`${pathNames.addActivation}?id=${item.activationId}`}
                                            >
                                                <EditIcon />
                                            </Link>
                                        )}
                                        {item.availableActions['download-template'] && (
                                            <button
                                                className={action}
                                                type={'button'}
                                                onClick={() => {
                                                    downloadDocument(item.templateDocument);
                                                }}
                                            >
                                                <DownloadIcon />
                                            </button>
                                        )}
                                    </div>
                                    <Button
                                        className={details}
                                        as={'link'}
                                        to={`${pathNames.activation}?id=${item.activationId}`}
                                        stylePreset={'secondary'}
                                        showPointer={false}
                                    >
                                        {t.details}
                                    </Button>
                                </div>
                            );
                        })}
                        <Pagination className={pagination} paginationPaths={paginationPaths} />
                    </div>
                </>
            )}
        </PanelLayout>
    );
};

export default ActivationsList;

function getStatusName(status: TActivationStatus | undefined, t: TLocale['ActivationsList']) {
    switch (status) {
        case 'new':
            return t.status.new;
        case 'waiting-for-approval':
            return t.status.waiting;
        case 'approved-waiting-for-copy':
            return t.status.approvedWaiting;
        case 'approval-copy':
            return t.status.approvalCopy;
        case 'approved-with-all-documents':
            return t.status.approved;
        case 'request-for-improvement':
            return t.status.request;
        case 'rejected':
            return t.status.rejected;
        default:
            return '';
    }
}

function getStatusClass(status: TActivationStatus | undefined) {
    switch (status) {
        case 'new':
            return statusNew;
        case 'waiting-for-approval':
            return statusWaiting;
        case 'approved-waiting-for-copy':
            return statusApprovedWaiting;
        case 'approval-copy':
            return statusApprovalCopy;
        case 'approved-with-all-documents':
            return statusApproved;
        case 'request-for-improvement':
            return statusRequest;
        case 'rejected':
            return statusRejected;
        default:
            return '';
    }
}

function getInitialParams() {
    const date = new Date();
    const month =
        date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : (date.getMonth() + 1).toString();
    const year = date.getFullYear().toString();

    return { year: year, month: month };
}
