// extracted by mini-css-extract-plugin
export var action = "activations-list-module--action--8e658";
export var badge = "activations-list-module--badge--11f68";
export var container = "activations-list-module--container--f908e";
export var details = "activations-list-module--details--444d8";
export var empty = "activations-list-module--empty--aab49";
export var headerItem = "activations-list-module--header-item--ca31a";
export var initialLoading = "activations-list-module--initialLoading--3eb8c";
export var listHeader = "activations-list-module--list-header--7d8da";
export var listItem = "activations-list-module--list-item--de4ea";
export var loader = "activations-list-module--loader--128d5";
export var loading = "activations-list-module--loading--d215d";
export var name = "activations-list-module--name--cdc2d";
export var pagination = "activations-list-module--pagination--17c00";
export var statusApprovalCopy = "activations-list-module--status-approval-copy--88673";
export var statusApproved = "activations-list-module--status-approved--35f79";
export var statusApprovedWaiting = "activations-list-module--status-approved-waiting--7fa3a";
export var statusNew = "activations-list-module--status-new--44ad9";
export var statusRejected = "activations-list-module--status-rejected--c31e8";
export var statusRequest = "activations-list-module--status-request--1f952";
export var statusWaiting = "activations-list-module--status-waiting--d79d9";